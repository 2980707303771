<template>
    <section class="relative text-white bg-cover bg-no-repeat bg-slate-900" :style="backgroundStyles">
        <UContainer class="grid gap-y-9 py-12" :class="{'lg:pt-16': !bgImage, 'lg:py-36': bgImage}">
            <div class="grid gap-y-8" :class="{'lg:max-w-3xl': bgImage}">
                <slot name="title" v-if="$slots['title-text']">
                    <h1 class="text-5xl font-bold lg:text-7xl before:block before:h-1 before:w-14 before:bg-blue-700 before:rounded before:mb-4 lg:max-w-3xl">
                        <slot name="title-text" />
                    </h1>
                </slot>

                <slot name="subtitle" v-if="$slots['subtitle-text']">
                    <h2 :class="{'text-xl': bgImage, 'text-4xl font-bold': !bgImage}">
                        <slot name="subtitle-text" />
                    </h2>
                </slot>

                <slot/>
            </div>

            <div v-if="$slots.bottom">
                <slot name="bottom"/>
            </div>
        </UContainer>
    </section>
</template>

<script setup>
const props = defineProps({
    bgImage: {
        type: String
    }
});

const backgroundStyles = computed(() => {
    if (!props.bgImage) {
        return {};
    }

    return {
        backgroundImage: `url('${props.bgImage}')`
    };
});
</script>
